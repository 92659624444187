import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import useAppUserFavorites from '@/core/model/user/store/useAppFavorites';

import useAppCart from '../../../../../../src/core/store/cart/useAppCart';
import SvgIcon from '../../../../../SvgIcon';
import TypographyLegacy from '../../../../../Typography';

import carpetIcon from './assets/carpet.svg';
import cartIcon from './assets/cart.svg';
import favoriteIcon from './assets/favorite.svg';
import homeIcon from './assets/home.svg';
import profileIcon from './assets/profile.svg';

import styles from './TabBar.module.css';

const Typography: any = TypographyLegacy;

interface TabBarProps {
  favoritesCount: number,
  cartCount: number,
  onMenuClick: (props: any) => void,
  isMenuOpen: boolean,
  stack: string[],
  user: any,
  onMenuCatalogClick: (props: any) => void,
  onSetStack: (props: string[]) => void,
}

const TabBar = (props: TabBarProps) => {
  const { isMenuOpen, stack, user, onMenuCatalogClick, onMenuClick, onSetStack } = props;

  const { items: cartItems } = useAppCart();

  const cartCount = cartItems?.reduce((acc, item) => acc + item.quantity, 0);

  // TODO: Убрать после рефакторинга лэйаута и выпиливания redux
  const { list: localFavorites } = useAppUserFavorites();
  const favoritesCount = localFavorites?.length ?? 0;

  const pathname = usePathname();

  const isAuthorized = !!user?.authorized;

  const handleCatalogClick = () => {
    if (isMenuOpen) {
      onSetStack(['main']);
      onMenuCatalogClick({ isOpen: false });
    } else {
      onSetStack(['main', 'catalog']);
      onMenuCatalogClick({ isOpen: true });
    }
  };

  const isCatalogSelected = stack?.join(',') === 'main,catalog';

  return (
    <div className={styles.items}>
      <Link
        href='/'
        prefetch
        className={classnames(styles.item, { [styles.selected]: !isCatalogSelected && pathname === '/' })}
        onClick={onMenuClick}
      >
        <div className={styles.media}>
          <SvgIcon width='24' height='24' svg={homeIcon} />
        </div>
        <Typography className={styles.title} variant='body3' component='span'>
          Главная
        </Typography>
      </Link>

      <button
        type='button'
        className={classnames(styles.item, { [styles.selected]: isCatalogSelected })}
        onClick={handleCatalogClick}
      >
        <div className={styles.media}>
          <SvgIcon width='24' height='24' svg={carpetIcon} />
        </div>
        <Typography className={styles.title} variant='body3' component='span'>
          Каталог
        </Typography>
      </button>

      <Link
        prefetch={false}
        href='/cart'
        className={classnames(styles.item, { [styles.selected]: !isCatalogSelected && pathname === '/cart' })}
        onClick={onMenuClick}
      >
        <div className={styles.media}>
          <SvgIcon width='24' height='24' svg={cartIcon} />
        </div>
        <Typography className={styles.title} variant='body3' component='span'>
          Корзина
        </Typography>
        {!!cartCount && <span className={styles.label} />}
      </Link>

      <Link
        prefetch={false}
        href='/favorites'
        className={classnames(styles.item, { [styles.selected]: !isCatalogSelected && pathname === '/favorites' })}
        onClick={onMenuClick}
      >
        <div className={styles.media}>
          <SvgIcon width='27' height='24' svg={favoriteIcon} />
        </div>
        <Typography className={styles.title} variant='body3' component='span'>
          Избранное
        </Typography>
        {!!favoritesCount && <span className={styles.label} />}
      </Link>

      <Link
        prefetch={false}
        href={`/cabinet${isAuthorized ? '/profile' : ''}`}
        className={classnames(styles.item, {
          [styles.selected]: !isCatalogSelected && pathname?.startsWith('/cabinet'),
        })}
        onClick={onMenuClick}
      >
        <div className={styles.media}>
          <SvgIcon width='25' height='24' svg={profileIcon} />
        </div>
        <Typography className={styles.title} variant='body3' component='span'>
          Профиль
        </Typography>
      </Link>
    </div>
  );
};

export default TabBar;
