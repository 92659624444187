import { useMemo } from 'react';

import { useAppStoreApi } from '@/core/store/AppStoreProvider';
import useAppStore from '@/core/store/useAppStore';
import { Id } from '@/shared/types';

const DEF_REGION_ID = 28;

export const useRegion = () => {
  const { setState } = useAppStoreApi();
  const region = useAppStore((state) => state.region);

  return useMemo(
    () => ({
      ...region,
      setRegion: (id: Id) => {
        setState((state) => {
          state.region.id = id;
        });
      },
    }),
    [setState, region]
  );
};

const useRegionId = () => {
  const region = useAppStore((state) => state.region);

  return region?.id ? +region.id : DEF_REGION_ID;
};

export default useRegionId;
