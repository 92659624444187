/* eslint-disable react/forbid-prop-types */
import React, { memo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import useAppUserFavorites from '@/core/model/user/store/useAppFavorites';

import logo from '../../../../../../assets/imgs/logo-test.svg';
import { declOfNum } from '../../../../../../helpers/tools';
import useMutationObserver from '../../../../../../hooks/useMutationObserver';
import useAppCart from '../../../../../../src/core/store/cart/useAppCart';
import { MODAL_KEY } from '../../../../../../src/core/store/ui';
import useAppUi from '../../../../../../src/core/store/ui/useAppUi';
import SvgIcon from '../../../../../SvgIcon';
import Typography from '../../../../../Typography';
import HeaderDropdownAction from '../HeaderDropdownAction';
import RegionConfirm from '../RegionConfirm';

import styles from './HeaderNav.module.css';

import ArrowInlineRightIcon from '/icons/ArrowInlineRightIcon';
import ArrowMiniDownIcon from '/icons/ArrowMiniDownIcon';
import BasketIcon from '/icons/BasketIcon';
import FavoriteIcon from '/icons/FavoriteIcon';
import LoginIcon from '/icons/LoginIcon';
import MenuIcon from '/icons/MenuIcon';
import PhoneIcon from '/icons/PhoneIcon';
import PointIcon from '/icons/PointIcon';
import ProfileIcon from '/icons/ProfileIcon';

const ProfileDropdown = dynamic(() => import('../ProfileDropdown'), { ssr: false });

const Logo = ({ className }) => (
  <div className={classnames(styles.defaultLogo, className)}>
    <SvgIcon width='200' height='68' svg={logo} />
  </div>
);

const HeaderNav = (props) => {
  const {
    shopsCount,
    currentShopsCount,
    user,
    region,
    domain,
    logout,
    isRegionConfirmationShowed,
    onToggleDrawer,
  } = props;

  const { items: cartItems } = useAppCart();

  const cartCount = cartItems?.reduce((acc, item) => acc + item.quantity, 0);

  const { setModal } = useAppUi();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  // TODO: Убрать после рефакторинга лэйаута и выпиливания redux
  const { list: localFavorites } = useAppUserFavorites();
  const favoritesCount = localFavorites?.length ?? 0;

  const isAuthorized = !!(user?.authorized);

  const phoneRef = useRef();
  const [openedDropdown, setOpenedDropdown] = useState();
  const [phoneHref, setPhoneHref] = useState('tel:+78003332135');

  useMutationObserver(
    phoneRef,
    (mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'href') {
          // console.log('MutationObserver', mutation, phoneRef.current?.href);
          setPhoneHref(phoneRef.current?.href);
        }
      });
    },
    { attributes: true }
  );

  const makeHandleToggleDropdown =
    (key) =>
    ({ isOpen }) => {
      if (isOpen) {
        setOpenedDropdown(key);
      } else if (key === openedDropdown) {
        setOpenedDropdown(null);
      }
    };

  const trackLogoClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Логотип',
      eventLabel: '',
    });
  };

  const trackPhoneClick = (e) => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Ссылка - телефон',
      eventLabel: e.target.innerText,
    });
  };

  const trackShopsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Адреса магазинов',
      eventLabel: `${currentShopsCount} ${declOfNum(shopsCount, ['Магазин', 'Магазина', 'Магазинов'])}`,
    });
  };

  const trackRegionsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Выбор региона',
      eventLabel: region?.name,
    });
  };

  const handleRegionSelectClick = () => {
    setModal({ id: MODAL_KEY.UPDATE_REGION });

    trackRegionsClick();
  };

  const handleShopsClick = () => {
    trackShopsClick();
  };

  const handleToggleDrawer = () => {
    if (onToggleDrawer) {
      onToggleDrawer();
    }
  };

  return (
    <nav
      className={classnames(styles.headerNav, 'header-nav')}
      itemScope
      itemType='https://schema.org/SiteNavigationElement'
    >
      <div className={styles.regionConfirmTablet}>
        {isLoaded && <RegionConfirm isRegionConfirmationShowed={isRegionConfirmationShowed} domain={domain} region={region} />}
      </div>
      <div className={classnames(styles.headerNavContent)}>
        <div className={classnames(styles.headerNavGroup)}>
          <div className={styles.regionConfirmNotTablet}>
            {isLoaded && <RegionConfirm isRegionConfirmationShowed={isRegionConfirmationShowed} domain={domain} region={region} />}
          </div>
          <button
            type='button'
            className={classnames(styles.headerNavMenuButton, styles.left, styles.notDesktopLarge)}
            onClick={handleToggleDrawer}
            aria-label='Переключить меню'
          >
            <MenuIcon />
          </button>

          <Link
            href='/'
            aria-label='На главную'
            prefetch
            className={classnames(styles.headerNavLogo, styles.headerNavLogoTablet, styles.notTablet)}
            onClick={trackLogoClick}
            itemProp='url'
          >
            <Logo />
          </Link>

          <Typography
            className={classnames(styles.headerNavLogoText, styles.desktopLarge)}
            variant='body2'
            component='span'
            color='primary'
            semibold
          >
            Мягкий шаг к счастью
          </Typography>

          <Link prefetch={false} aria-label='Адреса магазинов' href='/adresa-magazinov' passHref legacyBehavior>
            <Typography
              variant='body3'
              component='a'
              bold
              className={classnames(styles.headerNavShopLink)}
              onClick={handleShopsClick}
              itemProp='url'
            >
              <div className={styles.headerNavLinkIcon}><PointIcon /></div>
              {currentShopsCount}
              &nbsp;
              {declOfNum(currentShopsCount, ['Магазин', 'Магазина', 'Магазинов'])}
            </Typography>
          </Link>

          <Typography
            variant='body3'
            component='button'
            className={classnames(styles.headerNavRegionLink)}
            onClick={handleRegionSelectClick}
            type='button'
          >
            {region?.name}
            &nbsp;
            {<ArrowMiniDownIcon />}
          </Typography>
        </div>

        <div className={classnames(styles.headerNavGroup, styles.notTablet)}>
          <Typography
            variant='body2'
            component='a'
            bold
            color='primary'
            className={classnames(styles.headerNavPhone, 'call_phone_495')}
            href='tel:+78003332135'
            onClick={trackPhoneClick}
            itemProp='url'
            ref={phoneRef}
          >
            8 800 333-21-35
          </Typography>
        </div>

        <div className={classnames(styles.headerNavGroup, styles.desktopLarge)}>
          <Link prefetch={false} aria-label='Виртуальная примерка' href='/vr' passHref legacyBehavior>
            <Typography
              variant='body2'
              color='secondary'
              component='a'
              className={styles.headerNavRegularLink}
              itemProp='url'
            >
              Виртуальная примерка ковра
              <ArrowInlineRightIcon />
            </Typography>
          </Link>
          <Link prefetch={false} aria-label='Демонстрация' href='/demonstration' passHref legacyBehavior>
            <Typography
              variant='body2'
              color='secondary'
              component='a'
              className={styles.headerNavRegularLink}
              itemProp='url'
            >
              Демонстрация у вас дома
              <ArrowInlineRightIcon />
            </Typography>
          </Link>
        </div>

        <div className={styles.headerNavGroup}>
          <div className={styles.headerNavLogoTabletWrapper}>
            <Link
              aria-label='На главную'
              href='/'
              prefetch
              className={classnames(styles.headerNavLogo, styles.tablet)}
              onClick={trackLogoClick}
              itemProp='url'
            >
              <Logo />
            </Link>
          </div>
        </div>

        <div className={styles.headerNavGroup}>
          <HeaderDropdownAction
            className={classnames(styles.headerNavPointButton, styles.tablet)}
            href='/adresa-magazinov'
            theme='icon'
            icon={<PointIcon />}
            label='Адреса магазинов'
            prefetch={false}
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavPhoneButton, styles.notDesktopLarge)}
            href={phoneHref}
            theme='icon'
            icon={<PhoneIcon />}
            label='Контакты'
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavFavButton, styles.notTablet)}
            theme='icon'
            href='/favorites'
            badge={favoritesCount}
            icon={<FavoriteIcon />}
            title='Избранное'
            prefetch={false}
            label='Избранное'
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavBasketButton, styles.notTablet)}
            theme='icon'
            href='/cart'
            badge={cartCount}
            icon={<BasketIcon />}
            title='Корзина'
            prefetch={false}
            label='Корзина'
          />

          {isAuthorized ? (
            <HeaderDropdownAction
              className={classnames(styles.headerNavProfileButton, styles.desktopLarge)}
              theme='icon'
              icon={<ProfileIcon />}
              title='Профиль'
              popupProps={{
                popupWidth: 188,
                popupOffsetRight: 0,
              }}
              isOpen={openedDropdown === 'profile'}
              onToggleDropdown={makeHandleToggleDropdown('profile')}
              dropdown={<ProfileDropdown logout={logout} />}
            />
          ) : (
            <HeaderDropdownAction
              className={classnames(styles.headerNavCabinetButton, styles.desktopLarge)}
              theme='icon'
              href='/cabinet'
              icon={<LoginIcon />}
              title='Вход'
              label='Вход'
              prefetch={false}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

HeaderNav.propTypes = {
  shopsCount: PropTypes.number,
  currentShopsCount: PropTypes.number,
  user: PropTypes.object,
  region: PropTypes.object,
  showModal: PropTypes.func,
  logout: PropTypes.func,
  favorites: PropTypes.array,
  cart: PropTypes.object,
  isSearchFetching: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
  onToggleDropdown: PropTypes.func,
};

HeaderNav.defaultProps = {
  shopsCount: null,
  currentShopsCount: null,
  user: null,
  region: null,
  showModal: null,
  logout: null,
  favorites: null,
  cart: null,
  isSearchFetching: null,
  onToggleDrawer: null,
  onToggleDropdown: null,
};

export default memo(HeaderNav);
