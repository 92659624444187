'use client';

import React, { memo, useRef, useState } from 'react';
import classnames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import dynamic from 'next/dynamic';

import CloseIcon from '../../../../icons/CloseIcon';
import SearchIcon from '../../../../icons/SearchIcon';
import LayoutBanners from '../LayoutBanners';

// import useLayoutMeasures from '../../../../src/application/config/useLayoutMeasures';
import HeaderContent from './components/HeaderContent';
import HeaderHints from './components/HeaderHints';
import HeaderNav from './components/HeaderNav';
import TabBar from './components/TabBar';

import styles from './Header.module.css';

const DynamicDrawer = dynamic(() => import('./components/Drawer'), { ssr: false });
// const DynamicHeaderContent = dynamic(() => import('./components/HeaderContent'), { ssr: true });
// const DynamicHeaderHints = dynamic(() => import('./components/HeaderHints'), { ssr: true }); //
// const DynamicHeaderNav = dynamic(() => import('./components/HeaderNav'), { ssr: true });
// const DynamicTabBar = dynamic(() => import('./components/TabBar'), { ssr: true });
// const DynamicLayoutBanners = dynamic(() => import('../LayoutBanners'), { ssr: true });

const Header = (props) => {
  const {
    isFreeShippingDisabled,
    user,
    region,
    domain,
    colors,
    shopsCount,
    currentShopsCount,
    allArticles,
    isRegionConfirmationShowed,
  } = props;

  const searchInputRef = useRef();

  // const { headerRefEntry, onHeaderResize: onResize } = useLayoutMeasures();
  // const [setHeaderRef, ref] = headerRefEntry;
  // useResizeObserver({ target: ref, callback: onResize });

  const [scrollbarSizeValue, setScrollbarSizeValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [stack, setStack] = useState(['main']);

  const toggleFreezeBody = (value) => {
    const { body } = document;

    const scrollbarSizeVal = scrollbarSize();

    setScrollbarSizeValue(scrollbarSizeVal);

    if (value) {
      const windowScrollY = window.pageYOffset || window.scrollY;

      body.style.position = 'fixed';
      body.style.top = `-${windowScrollY}px`;
      body.style.paddingRight = `${scrollbarSizeVal}px`;
    } else {
      const bodyScrollY = body.style.top;

      body.style.position = '';
      body.style.top = '';
      body.style.paddingRight = '';

      window.scrollTo(0, parseInt(bodyScrollY || '0', 10) * -1);
    }
  };

  const handleToggleDrawer = () => {
    const value = !isDrawerOpen;

    setIsDrawerOpen(value);

    toggleFreezeBody(value);
  };

  const handleToggleCatalogDrawer = ({ isOpen }) => {
    setIsDrawerOpen(!!isOpen);

    toggleFreezeBody(!!isOpen);
  };

  const handleToggleDropdown = ({ isOpen }) => {
    toggleFreezeBody(isOpen);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);

    toggleFreezeBody(false);
  };

  const handleTabBarClick = () => {
    handleDrawerClose();
  };

  const handleClearSearchClick = () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';

      searchInputRef.current.focus();
    }
  };

  const headerStyle = {
    paddingRight: isDrawerOpen ? scrollbarSizeValue : 0,
  };

  return (
    <>
      <header
        id='header'
        className={classnames('header', styles.header)}
        style={headerStyle}
        itemScope
        itemType='https://schema.org/WPHeader'
      >
        <LayoutBanners mode='header' />

        <HeaderHints
          isFreeShippingDisabled={!!isFreeShippingDisabled}
          shopsCount={shopsCount}
          currentShopsCount={currentShopsCount}
          region={region}
        />

        <HeaderNav
          shopsCount={shopsCount}
          currentShopsCount={currentShopsCount}
          user={user}
          region={region}
          domain={domain}
          isDrawerOpen={isDrawerOpen}
          isRegionConfirmationShowed={isRegionConfirmationShowed}
          onToggleDropdown={handleToggleDropdown}
          onToggleDrawer={handleToggleDrawer}
        />

        <HeaderContent
          colors={colors}
          allArticles={allArticles}
        />

        {isDrawerOpen && (
          <DynamicDrawer
            colors={colors}
            shopsCount={shopsCount}
            stack={stack}
            region={region}
            user={user}
            isFreeShippingDisabled={!!isFreeShippingDisabled}
            isOpen={isDrawerOpen}
            onCloseClick={handleDrawerClose}
            onSetStack={setStack}
          />
        )}

        <div className={styles.search}>
          <form className={styles.searchContent}>
            <label htmlFor='search-mobile' className={styles.searchIcon}>
              <SearchIcon />
            </label>
            <input
              id='search-mobile'
              ref={searchInputRef}
              className={classnames(styles.searchInput, 'search-input', 'search-input__mobile')}
              placeholder='Поиск'
            />
            <button type='button' className={styles.searchClose} onClick={handleClearSearchClick}>
              <CloseIcon />
            </button>
          </form>
        </div>
      </header>

      <TabBar
        isMenuOpen={isDrawerOpen}
        stack={stack}
        user={user}
        onSetStack={setStack}
        onMenuClick={handleTabBarClick}
        onMenuCatalogClick={handleToggleCatalogDrawer}
      />
    </>
  );
};

export default memo(Header);
