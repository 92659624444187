'use client';

import React, { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';

import { BANNER_ID, BlackFridayStage } from '@/core/store/ui';

import useRegionId from '../../../../src/core/model/region/store/useRegionId';
import useAppUi from '../../../../src/core/store/ui/useAppUi';

// import BlackFridayBanner from './components/BlackFridayBanner';
import DemonstrationBanner from './components/DemonstrationBanner';

import style from './LayoutBanners.module.css';


interface LayoutBannersProps {
  mode?: 'header';
}

interface BannerProps {
  mode?: 'header';
}

const CurrentBanner = ({ mode }: BannerProps) => {
  const { banner } = useAppUi();

  let renderedBanner: ReactElement | null = null;

  if (banner?.id === BANNER_ID.BLACK_FRIDAY) {
    // renderedBanner = <BlackFridayBanner mode={mode} />;
  } else if (banner?.id === BANNER_ID.DEMONSTRATION) {
    renderedBanner = <DemonstrationBanner mode={mode} />;
  }

  return renderedBanner;
}

const LayoutBanners = ({ mode }: LayoutBannersProps) => {
  const { banner, setBanner } = useAppUi();
  const cityId = useRegionId();
  const pathname = usePathname();

  useEffect(() => {
    const checkCurrentBanner = () => {
      // const blackFridayValue = global.window?.localStorage?.getItem('blackFriday');
      // const blackFriday = blackFridayValue ? JSON.parse(blackFridayValue) : {};
      // const isBlackFridayAllowed = false && cityId !== 44;

      // if (false && blackFriday?.timestamp) {
      // eslint-disable-next-line max-len
      //   if ((blackFriday.state === BlackFridayStage.START || blackFriday.state === BlackFridayStage.PAUSE) && (Date.now() > (blackFriday.timestamp + 1000 * 60 * 60 * 24 * 3))) {
      //     global.window?.localStorage?.setItem('blackFriday', JSON.stringify({
      //       timestamp: Date.now(),
      //       state: BlackFridayStage.STOP,
      //     }));
      //
      //     setBanner({
      //       id: BANNER_ID.BLACK_FRIDAY,
      //       value: {
      //         timestamp: Date.now(),
      //         state: BlackFridayStage.STOP,
      //       },
      //     });
      //
      //     return;
      //   }
      //
      //   if (isBlackFridayAllowed) {
      //     setBanner({
      //       id: BANNER_ID.BLACK_FRIDAY,
      //       value: blackFriday,
      //     });
      //
      //     return;
      //   }
      // }

      if (pathname?.includes('/catalog') || pathname?.includes('/product')) {
        setBanner({
          id: BANNER_ID.DEMONSTRATION,
          value: null,
        });

        return;
      }
    }

    checkCurrentBanner();
  }, [setBanner, cityId, pathname]);

  // eslint-disable-next-line max-len
  const isBlackFridayBannerShow = banner?.id === BANNER_ID.BLACK_FRIDAY && ((banner?.value?.state === BlackFridayStage.START || banner?.value?.state === BlackFridayStage.PAUSE) && (banner?.value?.timestamp ?? 0) + 1000 * 60 * 60 * 24 * 3);

  const isDemonstrationBannerShow = pathname?.includes('/catalog') || pathname?.includes('/product');

  return (isBlackFridayBannerShow || isDemonstrationBannerShow) && (
    <div className={classNames(style.root, { [style.headerMode]: mode === 'header' })}>
      <CurrentBanner mode={mode} />
    </div>
  );
};


export default LayoutBanners;
